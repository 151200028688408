import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Blogs.css'; // Ensure this points to your CSS file with the enhanced styles

// Importing images
import blog1 from '../assets/blog1.gif';
import blog2 from '../assets/blog2.gif';
import blog3 from '../assets/blog3.gif';
import blog4 from '../assets/blog4.gif';
import blog5 from '../assets/blog5.gif';
import blog6 from '../assets/blog6.gif';
import blog7 from '../assets/blog7.gif';
import { Helmet } from 'react-helmet';

Modal.setAppElement('#root');

const canonicalUrl = "https://www.nealfrazier.tech/websites/Blogs";

// Custom styles for the modal
const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Slightly darker overlay for better focus on the modal
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1050, // Ensure it's above other elements
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%) scale(0.9)', // Start slightly smaller for a pop effect
    border: '1px solid #fff', // Light border for a subtle contrast
    borderRadius: '15px', // More rounded corners for a modern look
    padding: '25px',
    boxShadow: '0 10px 30px rgba(0,0,0,0.2)', // More pronounced shadow for depth
    maxWidth: '500px', // Slightly smaller for focus
    width: '80%', // More responsive width
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', // Fallback fonts
    background: '#f9f9f9', // Light background for a clean look
    color: '#333', // Dark text for readability
    opacity: 1, // Start invisible for a fade-in effect
    transition: 'opacity 250ms ease-in-out, transform 250ms ease', // Smooth transitions for opacity and scale
    overflow: 'auto', // Ensure content is scrollable if it overflows
    WebkitOverflowScrolling: 'touch', // Smooth scrolling on touch devices
  },
};


const Blogs = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
 
  const [modalContentOpacity, setModalContentOpacity] = useState(0); // Control the opacity of modal content

  useEffect(() => {
    // When modal is open, fade the content in. When closed, fade it out.
    if (modalIsOpen) {
      setModalContentOpacity(1);
    } else {
      setModalContentOpacity(0);
    }
  }, [modalIsOpen]);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);
 
  

  const blogSections = [
    {
      id: 1,
      title: "Why Choose Us",
      content: "With the digital landscape more competitive than ever, a generic blog won't cut it. Our bespoke blog development services ensure your site stands out, engages your target audience, and drives conversions. We don't just create; we strategize to make your blog a cornerstone of your digital presence.",
      imgSrc: blog1
    },
    {
      id: 2,
      title: "Our Process",
      content: "Our collaborative process is tailored to your unique needs and vision. Starting with a detailed consultation, we move to design and development, incorporating SEO and content strategy, and culminating in a launch that makes an impact. We support you at every step, ensuring a smooth journey to success.",
      imgSrc: blog2
    },
    {
      id: 3,
      title: "Portfolio",
      content: "Our portfolio showcases a variety of successful blogs, from industry leaders to niche passion projects. Each design is a testament to our commitment to excellence and our ability to elevate brands through thoughtful design and strategic content placement.",
      imgSrc: blog3
    },
    {
      id: 4,
      title: "Testimonials",
      content: "Don't just take our word for it. Hear from our satisfied clients who have seen their traffic soar and their communities thrive thanks to our comprehensive blog development services. Their success stories reflect our dedication to transforming ideas into digital realities.",
      imgSrc: blog4
    },
    {
      id: 5,
      title: "FAQ",
      content: "Your questions, answered. We tackle the most common inquiries about blog development, from timeline and cost to customization and support, providing clear, concise information to help you make an informed decision.",
      imgSrc: blog5
    },
    {
      id: 6,
      title: "Blog Tips & Tricks",
      content: "Even the most seasoned bloggers need a tip or two. Our expert insights into SEO, content creation, and digital marketing strategies can help you keep your blog fresh, relevant, and ahead of the curve. We share our knowledge so you can continue to grow your digital presence long after launch.",
      imgSrc: blog6
    },
    {
      id: 7,
      title: "Introducing Bloggy: Your AI Blogging Assistant",
      content: "Imagine having an AI assistant that helps brainstorm, draft, and even write content for your blog. For just $20 a month, Bloggy enhances your blogging strategy with AI-powered insights and content creation. Whether you're combating writer's block or looking for data-driven content suggestions, Bloggy is your go-to solution, seamlessly integrating with our blog development services to give you an unbeatable edge.",
      imgSrc: blog1 // Assuming you might want to reuse or have a specific image for Bloggy
    },
    {
      id: 8,
      title: "Boost Your Blog's Visibility",
      content: "Elevate your blog to the next level with our cutting-edge SEO strategies and customizable themes. Our expert team ensures your blog not only stands out with a unique design but also ranks high on search engines, driving more traffic and engagement. Unlock powerful tools and features designed to give you an edge in content creation, audience analytics, and more. Don't let your blog get lost in the digital shuffle—partner with us to make your mark online. Ready to boost your blog's visibility and captivate your audience? Let's make it happen together. Your journey to becoming an industry leader starts now.",
      imgSrc: blog7 // Replace with the appropriate image import for this section
    }
    
    
  ];
  

  


  return (
    <div className="blog-container">
      <Helmet>
  <title>Unlock Your Blog's Potential - Neal Frazier Tech</title>
  <meta name="description" content="Transform your blog with Neal Frazier Tech's bespoke blog development services. Engage your audience, drive conversions, and make your blog a cornerstone of your digital presence. Our tailored process, SEO strategies, and AI assistant Bloggy ensure your blog stands out in a competitive landscape." />
  <meta name="keywords" content="blog development, SEO, content strategy, blog design, AI blogging assistant, Neal Frazier Tech, blog tips, blog visibility, digital marketing" />
  <meta property="og:title" content="Unlock Your Blog's Potential - Neal Frazier Tech" />
  <meta property="og:description" content="Elevate your blog with Neal Frazier Tech's comprehensive blog development services. From design and SEO to AI-powered content creation with Bloggy, we provide everything you need to engage your audience and drive success." />
  <link rel="canonical" href={canonicalUrl} />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Unlock Your Blog's Potential",
        "description": "Transform your blog with Neal Frazier Tech's bespoke blog development services. Engage your audience, drive conversions, and make your blog a cornerstone of your digital presence. Our tailored process, SEO strategies, and AI assistant Bloggy ensure your blog stands out in a competitive landscape.",
        "publisher": {
          "@type": "Organization",
          "name": "Neal Frazier Tech",
          "logo": {
            "@type": "ImageObject",
            "url": "https://nealfrazier.tech/logo.png"
          }
        },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://nealfrazier.tech/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Unlock Your Blog's Potential",
              "item": "https://nealfrazier.tech/blogs"
            }
          ]
        }
      }
    `}
  </script>
</Helmet>


      <header className="blog-header">
        <h1 className="tracking-in-expand">Unlock Your Blog's Potential</h1>
      </header>
      
      <div className="blog-grid">
        {blogSections.map(section => (
          <section key={section.id} className="blog-section">
            <div className="text-content">
              <h2>{section.title}</h2>
              <p>{section.content}</p>
            </div>
            <img className="blog-image" src={section.imgSrc} alt="blog-gif" />
          </section>
        ))}
      </div>
      <div className="button-container">
      <button onClick={handleOpenModal} className="button-engaging">Get Your Blog Started Today</button>
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} style={customModalStyles} contentLabel="Contact Form">
  <div className="modal-content" style={{ opacity: modalContentOpacity }}>
    <h2>Let's Bring Your Blog to Life</h2>
    {/* Add `netlify` attribute and a unique name for the form */}
    <form name="contact" method="POST" data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label>Your Name: <input required type="text" name="name" /></label>
      </p>
      <p>
        <label>Your Email: <input required type="email" name="email" /></label>
      </p>
      <p>
        <label>Message (Describe your Blog Service Needs or Desires): <textarea required name="message"></textarea></label>
      </p>
      <p>
        <button type="submit">Send</button>
      </p>
    </form>
  </div>
</Modal>

    </div>
  );
};

export default Blogs;
