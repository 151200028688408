import React, { useState, useEffect, useRef } from 'react';
import styles from './styles/NFTBlogs.module.css';
import logo from './assets/newlogo.png';
import { Link } from 'react-router-dom';
import microphoneIcon from './assets/microphone.png'; // Add microphone icon
import microphoneActiveIcon from './assets/voice.png'; // Add active microphone icon
import { Helmet } from 'react-helmet';

function NFTBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [isListening, setIsListening] = useState(false); 
  const searchInputRef = useRef(null);

  useEffect(() => {
    // Fetch blogs data
    fetch('/data/blogs.json')
      .then(response => response.json())
      .then(data => {
        setBlogs(data);
        setFilteredBlogs(data);
      })
      .catch(error => console.error('Error fetching blogs:', error));
  }, []);

  useEffect(() => {
    // Filter blogs based on search query
    const normalizedQuery = searchQuery.toLowerCase();
    const filtered = blogs.filter(blog => {
      return blog.title.toLowerCase().includes(normalizedQuery) ||
             blog.summary.toLowerCase().includes(normalizedQuery);
    });
    setFilteredBlogs(filtered);
  }, [searchQuery, blogs]);

  const handleVoiceSearchClick = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();

      recognition.onstart = () => {
        setIsListening(true); // Update listening state
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setSearchQuery(transcript);
        setIsListening(false); // Update listening state
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsListening(false); // Update listening state on error
        alert('There was an error with voice search. Please try again.');
      };

      recognition.start();
    } else {
      alert('Speech recognition is not supported in this browser.');
    }
  };

  return (
    <div className={styles.blogsContainer}>
     <Helmet>
  <title>Learn Essential Digital Tips & Micro-Educate Over Doomscrolling - Neal Frazier Tech</title>
  <meta name="description" content="Unlock your blog's potential with Neal Frazier Tech's comprehensive guides and tips on web development, SEO, cybersecurity, and more. Stay ahead with our expertly curated content and innovative solutions designed to enhance your digital presence." />
  <meta name="keywords" content="digital tips, micro-education, web development, SEO, cybersecurity, Neal Frazier Tech, blog development, React, JavaScript, content creation" />
  <meta property="og:title" content="Learn Essential Digital Tips & Micro-Educate Over Doomscrolling - Neal Frazier Tech" />
  <meta property="og:description" content="Discover essential digital tips and micro-educate yourself with Neal Frazier Tech's expertly curated content. From mastering React and SEO to cybersecurity and content creation, enhance your digital presence with our innovative solutions." />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Learn Essential Digital Tips & Micro-Educate Over Doomscrolling",
        "description": "Unlock your blog's potential with Neal Frazier Tech's comprehensive guides and tips on web development, SEO, cybersecurity, and more. Stay ahead with our expertly curated content and innovative solutions designed to enhance your digital presence.",
        "publisher": {
          "@type": "Organization",
          "name": "Neal Frazier Tech",
          "logo": {
            "@type": "ImageObject",
            "url": "https://nealfrazier.tech/logo.png"
          }
        },
        "mainEntity": [
          {
            "@type": "Article",
            "headline": "Mastering React in 2024: A Comprehensive Guide",
            "url": "https://nealfrazier.tech/mastering-react-2024"
          },
          {
            "@type": "Article",
            "headline": "The SEO Checklist for Web Developers",
            "url": "https://nealfrazier.tech/seo-checklist-web-developers"
          },
          {
            "@type": "Article",
            "headline": "Building Secure Web Applications: Best Practices",
            "url": "https://nealfrazier.tech/secure-web-applications-best-practices"
          },
          {
            "@type": "Article",
            "headline": "The Future of Web Development: Trends to Watch",
            "url": "https://nealfrazier.tech/future-web-development-trends"
          },
          {
            "@type": "Article",
            "headline": "Demystifying SEO: Strategies for 2024",
            "url": "https://nealfrazier.tech/demystifying-seo-strategies-2024"
          }
          
        ],
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://nealfrazier.tech/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Blog",
              "item": "https://nealfrazier.tech/nftblogs"
            }
          ]
        }
      }
    `}
  </script>
</Helmet>


      <h1>NFT Blogs: Learn Essential Digital Tips & Micro-Educate Over Doomscrolling</h1>

      <Link to="/">
        <img 
          className="logo" 
          src={logo} 
          alt="Neal Frazier Tech Logo" 
        />
      </Link>
      <h4>You can filter through the blog posts by typing or speaking keywords in the search bar.</h4>

      

      <div className={styles.searchContainer}>
        <input
          type="text"
          ref={searchInputRef} 
          placeholder="Search blogs..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          className={styles.searchBar}
        />
        <button 
          onClick={handleVoiceSearchClick} 
          className={styles.microphoneButton}
          disabled={isListening} // Disable while listening
        >
          <img className="voice-button" src={isListening ? microphoneActiveIcon : microphoneIcon} alt="Voice Search" />
        </button>
        </div>

{/* Blog List Rendering */}
{filteredBlogs.length > 0 ? (
  <ul className={styles.blogsList}>
    {filteredBlogs.map(blog => (
      <li key={blog.id} className={styles.blogItem}>
        <img src={blog.image} className="blog-image" alt="Neal Frazier Tech" />
        <a href={blog.url} target="_blank" rel="noopener noreferrer" className={styles.blogTitle}>
          <h3>{blog.title}</h3>
        </a>
        <p className={styles.blogSummary}>{blog.summary}</p>
        <a href={blog.url} target="_blank" rel="noopener noreferrer" className={styles.readMoreLink}>Read More</a>
      </li>
    ))}
  </ul>
) : (
  <p>No blogs found matching your search.</p>
)}
</div>
  );
}

export default NFTBlogs;