import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaDoorOpen, FaKey, FaTv, FaCar, FaNetworkWired, FaUserSecret, FaSignal, FaHome, FaLock } from 'react-icons/fa';
import flipperImage from './flipper.png'; 
import flipperGIF from './flipper.gif';
import '../styles/FlipperServices.css';
import FlipperContactForm from './FlipperContactForm';
import { Helmet } from 'react-helmet';

const FlipperServices = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    let lastScrollTop = 0;
    const flipperGif = document.getElementById('flipper-gif');

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        // Scrolling down
        flipperGif.style.transform = 'translateX(100vw)';
      } else {
        // Scrolling up
        flipperGif.style.transform = 'translateX(-100vw)';
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const services = [
    { icon: <FaDoorOpen />, title: 'NFC Door Card Creation', description: 'Secure access cards for company doors.', example: 'Example: Create access cards for office doors to enhance security.' },
    { icon: <FaKey />, title: 'RFID Key Duplication', description: 'Duplicate RFID keys for employees.', example: 'Example: Duplicate employee RFID badges for secure building access.' },
    { icon: <FaTv />, title: 'TV Remote Programming', description: 'Custom TV remote programming and duplication.', example: 'Example: Program universal remotes for office TVs.' },
    { icon: <FaCar />, title: 'Car Key Duplication', description: 'Duplicate and program car keys.', example: 'Example: Duplicate spare keys for company vehicles.' },
    { icon: <FaNetworkWired />, title: 'Social Network NFC Cards', description: 'Create NFC cards for quick social network sharing.', example: 'Example: Share LinkedIn profiles instantly with NFC business cards.' },
    { icon: <FaUserSecret />, title: 'Security Auditing', description: 'Evaluate and enhance company security protocols using Flipper Zero.', example: 'Example: Perform security audits on office networks.' },
    { icon: <FaSignal />, title: 'Wireless Signal Analysis', description: 'Analyze and troubleshoot wireless signals.', example: 'Example: Troubleshoot WiFi signal issues in large offices.' },
    { icon: <FaHome />, title: 'Smart Home Integration', description: 'Integrate and control smart home devices.', example: 'Example: Control office lighting and thermostats with smart devices.' },
    { icon: <FaLock />, title: 'Penetration Testing', description: 'Security testing for NFC/RFID vulnerabilities.', example: 'Example: Test and secure RFID-enabled office locks.' },
  ];

  return (
    <div className="flipper-services" style={{ background: '#f0f8ff', color: '#00274c', padding: '50px 20px' }}>
      <Helmet>
  <title>Flipper Zero Services - Neal Frazier Tech</title>
  <meta name="description" content="Explore our wide range of services including NFC door card creation, RFID key duplication, TV remote programming, car key duplication, social network NFC cards, security auditing, wireless signal analysis, smart home integration, and penetration testing with Flipper Zero." />
  <meta name="keywords" content="Flipper Zero, NFC door cards, RFID key duplication, TV remote programming, car key duplication, social network NFC cards, security auditing, wireless signal analysis, smart home integration, penetration testing, Neal Frazier Tech" />
  <meta property="og:title" content="Flipper Zero Services - Neal Frazier Tech" />
  <meta property="og:description" content="Discover Neal Frazier Tech's Flipper Zero services: NFC door card creation, RFID key duplication, TV remote programming, car key duplication, social network NFC cards, security auditing, wireless signal analysis, smart home integration, and penetration testing." />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Flipper Zero Services",
        "description": "Explore our wide range of services including NFC door card creation, RFID key duplication, TV remote programming, car key duplication, social network NFC cards, security auditing, wireless signal analysis, smart home integration, and penetration testing with Flipper Zero.",
        "publisher": {
          "@type": "Organization",
          "name": "Neal Frazier Tech",
          "logo": {
            "@type": "ImageObject",
            "url": "https://nealfrazier.tech/logo.png"
          }
        },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://nealfrazier.tech/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Flipper Zero Services",
              "item": "https://nealfrazier.tech/Flipper-Services"
            }
          ]
        }
      }
    `}
  </script>
</Helmet>


      <div className="container">
        <img className="flipper-logo" src={flipperImage} alt="Flipper Zero Logo" />
        <h1 className="flipper-services-title text-center">Flipper Services</h1>
        <h2 className="flipper-services-subtitle text-center">Explore Our Wide Range of Services</h2>
        <div className="row">
          {services.map((service, index) => (
            <div className="col-md-4" key={index} data-aos="fade-up" data-aos-delay={index * 100}>
              <div className="service-box" style={{
                border: '2px solid #00274c',
                borderRadius: '10px',
                padding: '20px',
                margin: '10px',
                textAlign: 'center',
                backgroundColor: '#ffffff'
              }}>
                <div style={{ fontSize: '40px', color: '#ff6600' }}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <p><strong>Example:</strong> {service.example}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <img id="flipper-gif" className="flipper-gif" src={flipperGIF} alt="Flipper Zero GIF" />
      <FlipperContactForm />
    </div>
  );
};

export default FlipperServices;
