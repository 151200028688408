import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ContactForm from './ContactForm'; // Ensure this is the correct path to your form component
import Modal from 'react-modal';
import BackgroundAnimation from './BackgroundAnimation'; // Ensure this is a water-themed animation component
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { Helmet } from 'react-helmet';

// Modal Accessibility
Modal.setAppElement('#root');

// Styled components
const Container = styled.div`
padding-top: 50px;
padding-bottom: 50px;
  background-color: #004d99; // Rich blue background for brand consistency
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; // Center vertically
  align-items: center; // Center horizontally
  
  text-align: center;
  min-height: 100vh; // Fill up the screen height
  position: relative;
  overflow: hidden;
`;

const Title = styled.h1`
margin-top: 100px;
  color: #ff5722; // Vibrant orange for emphasis
`;

const Text = styled.p`
text-align: center;
  margin: 5px 0;
  line-height: 1.4;
  width: 50vw;
`;

const ContactButton = styled.button`
  background-color: #ff5722; // Vibrant orange to stand out
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
margin-top: 20px;
  &:hover {
    background-color: #e56122; // Darker shade for hover effect
  }
`;

// Custom Modal styling
const CustomModal = styled(Modal)`
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
`;




const WebDevelopment = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [websiteType] = useState('');

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the form from submitting traditionally
    console.log({ websiteType }); // Here you can include other form data as well
    closeModal();
  };

  return (
    <>
  <Helmet>
  <title>Elite Web Development Services - Neal Frazier Tech</title>
  <meta name="description" content="Transform your digital presence with our cutting-edge web development services. Tailored to your unique needs, our solutions enhance user experience, boost engagement, and drive growth. Contact us today to start your project and make your vision a reality." />
  <meta name="keywords" content="web development, digital presence, user experience, engagement, growth, Neal Frazier Tech, start your project" />
  <meta property="og:title" content="Elite Web Development Services - Neal Frazier Tech" />
  <meta property="og:description" content="Transform your digital presence with Neal Frazier Tech's cutting-edge web development services. Enhance user experience, boost engagement, and drive growth. Start your project today." />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Elite Web Development Services",
        "description": "Transform your digital presence with our cutting-edge web development services. Tailored to your unique needs, our solutions enhance user experience, boost engagement, and drive growth. Contact us today to start your project and make your vision a reality.",
        "publisher": {
          "@type": "Organization",
          "name": "Neal Frazier Tech",
          "logo": {
            "@type": "ImageObject",
            "url": "https://nealfrazier.tech/logo.png"
          }
        },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://nealfrazier.tech/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Elite Web Development Services",
              "item": "https://nealfrazier.tech/web-development"
            }
          ]
        }
      }
    `}
  </script>
</Helmet>

    <Container data-aos="fade">
      <BackgroundAnimation /> {/* Background animation component */}
      <Title className="tracking-in-expand" data-aos="fade">Elite Web Development Services</Title>
      <Text data-aos="fade-up">Transform your digital presence with our cutting-edge web development services. Tailored to your unique needs, our solutions enhance user experience, boost engagement, and drive growth. Let's create something amazing together.</Text>
      <Text data-aos="fade-down">Don't let your business get left behind in the digital era. Contact us today to start your project and make your vision a reality.</Text>
      <ContactButton data-aos="slide-up" onClick={openModal}>Start Your Project Today</ContactButton>
      <CustomModal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Contact Form">
        <ContactForm onSubmit={handleFormSubmit}>
         
          <ContactButton type="submit" style={{ marginTop: '20px' }}>Submit</ContactButton>
        </ContactForm>
      </CustomModal>
    </Container>
    </>
  );
};

export default WebDevelopment;