import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  background-color: white;
  background-image: linear-gradient(135deg, #1565C0 25%, transparent 25%), 
                    linear-gradient(225deg, #FF6F00 25%, transparent 25%), 
                    linear-gradient(315deg, #F57C00 25%, transparent 25%), 
                    linear-gradient(45deg, #1E88E5 25%, transparent 25%);
  background-size: 500px;
  background-position: 0 0, 30px 30px, 30px 30px, 0 0;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 9;
`;

const NavbarContent = styled.div`
  display: flex;
  align-items: center;
`;

const ContactButton = styled.button`
  background-color: #ff5722;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.3s ease, background-color 0.3s ease;
  transform: ${({ scrollUp }) => scrollUp ? 'translateX(0)' : 'translateX(-30px)'};

  &:hover {
    background-color: #e64a19;
  }
`;

const Navbar = () => {
  const navigate = useNavigate();
  const [scrollUp, setScrollUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const navigateAndScroll = () => {
    navigate("/");
    setTimeout(() => {
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollUp(false);
      } else {
        setScrollUp(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  return (
    <NavbarContainer>
      <NavbarContent>
     
      </NavbarContent>
      <ContactButton onClick={navigateAndScroll} scrollUp={scrollUp}>
        Receive a free consultation today!
      </ContactButton>
    </NavbarContainer>
  );
};

export default Navbar;