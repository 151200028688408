import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import './styles/ContactMe.css'; // Ensure this CSS enhances React Bootstrap components
import styled from 'styled-components';

// Styled components definitions remain the same

const ContactMe = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent] = useState({ title: '', message: '' });

    const StyledModal = styled(Modal)`
    .modal-content {
      background-color: #004d99; /* Rich blue background */
      color: white; /* White text color */
      border-radius: 10px; /* Rounded corners for the modal */
    }
  `;
  
  const StyledModalHeader = styled(Modal.Header)`
    border-bottom: 1px solid #ff5722; /* Vibrant orange border for the header */
    .close {
      color: white; /* Ensure close button is visible against the header */
    }
  `;
  
  const StyledModalTitle = styled(Modal.Title)`
    color: white; /* White title text */
  `;
  
  const StyledModalBody = styled(Modal.Body)`
    background-color: #0066cc; /* A slightly lighter blue for the body */
  `;
  
  const StyledModalFooter = styled(Modal.Footer)`
    border-top: 1px solid #ff5722; /* Vibrant orange border for the footer */
    .btn-secondary {
      background-color: #ff5722; /* Use the vibrant orange for the close button */
      border: none; /* Remove default border */
      &:hover {
        background-color: #e56122; /* Darker orange on hover */
      }
    }
  `;
  
  // Update your existing Modal component usage to use these styled components
  


return (
    <div className="contact-container" id="contact">
       
    <h2>Request A Consultation Today!</h2>
    {/* Add `netlify` attribute and a unique name for the form */}
    <div className="contact-form">
    <Form method="POST" className="contact-form" data-netlify="true">
  <input type="hidden" name="form-name" value="contact" />
  <input type="hidden" name="bot-field" />
  <Form.Group controlId="formBasicName" className="custom-form-group">
    <Form.Label>Your Name:</Form.Label>
    <Form.Control type="text" name="name" placeholder="Enter your name" required />
  </Form.Group>
  <Form.Group controlId="formBasicEmail" className="custom-form-group">
    <Form.Label>Your Email:</Form.Label>
    <Form.Control type="email" name="email" placeholder="Enter your email" required />
  </Form.Group>
  <Form.Group controlId="formBasicMessage" className="custom-form-group">
    <Form.Label>Message For Consultation:</Form.Label>
    <Form.Control as="textarea" name="message" rows={3} placeholder="Your message" required />
  </Form.Group>
  <Button variant="primary" type="submit" className="custom-submit-button">
    Request a Consultation
  </Button>
</Form>

    </div>
 

            <StyledModal show={showModal} onHide={() => setShowModal(false)}>
  <StyledModalHeader closeButton>
    <StyledModalTitle>{modalContent.title}</StyledModalTitle>
  </StyledModalHeader>
  <StyledModalBody>{modalContent.message}</StyledModalBody>
  <StyledModalFooter>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Close
    </Button>
  </StyledModalFooter>
</StyledModal>

        </div>
    );
};

export default ContactMe;
