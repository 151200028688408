import React from 'react';
import styles from '../styles/BlogPost.module.css'; // Ensure this matches your file structure
import logo from './NealFrazier.png'; // Ensure the logo is updated to reflect JavaScript
import BlogNavBar from '../BlogNavBar';
import { Link } from 'react-router-dom';

const JavaScriptCheatSheetBlog = () => {
    return (
      <article className={styles.blogPost}>
        <header className={styles.blogHeader}>
          <Link to="/nftblogs">
          <img src={logo} alt="JavaScript Cheat Sheet 2024" />
          </Link>
          <h1>JavaScript Cheat Sheet: Essential Concepts & Syntax</h1>
        </header>
        <section className={styles.blogContent}>
          <p>
            JavaScript is a versatile language powering the dynamic behavior on most websites. This cheat sheet compiles essential syntax, concepts, and tips to help you navigate and master JavaScript more efficiently.
          </p>
          <h2>1. Variables & Data Types</h2>
          <p>
            JavaScript supports dynamic types that can be changed with the <code>var</code>, <code>let</code>, and <code>const</code> keywords. Understand the basics of variables and data types through the <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Grammar_and_Types" target="_blank" rel="noopener noreferrer">MDN Web Docs</a>.
          </p>
          <h2>2. Functions</h2>
          <p>
            Functions are blocks of code designed to perform a particular task. JavaScript provides function declarations, expressions, and arrow functions. Explore function concepts at <a href="https://javascript.info/function-basics" target="_blank" rel="noopener noreferrer">JavaScript.info</a>.
          </p>
          <h2>3. Control Structures</h2>
          <p>
            Control structures like <code>if</code>, <code>else</code>, <code>switch</code>, and loops (<code>for</code>, <code>while</code>) control the flow of your code. Master control structures with <a href="https://www.learn-js.org/en/Control_Structures" target="_blank" rel="noopener noreferrer">Learn-js.org</a>.
          </p>
          <h2>4. DOM Manipulation</h2>
          <p>
            The Document Object Model (DOM) is a programming interface for web documents. It allows programs to change the document structure, style, and content. Get started with DOM manipulation on <a href="https://www.digitalocean.com/community/tutorials/introduction-to-the-dom" target="_blank" rel="noopener noreferrer">DigitalOcean</a>.
          </p>
          <h2>5. Events</h2>
          <p>
            Events are actions or occurrences that happen in the system you are programming, which the system tells you about so you can respond to them in some way. Learn about JavaScript events at <a href="https://developer.mozilla.org/en-US/docs/Web/Events" target="_blank" rel="noopener noreferrer">MDN Web Docs</a>.
          </p>
          <h2>6. Promises and Async/Await</h2>
          <p>
            Asynchronous programming is essential for performing long network requests without blocking the main thread. Understand Promises and Async/Await through the <a href="https://javascript.info/async" target="_blank" rel="noopener noreferrer">JavaScript.info guide</a>.
          </p>
          <h2>7. ES6+ Features</h2>
          <p>
            ECMAScript 6 (ES6) and later versions have introduced many new features for more expressive and concise JavaScript. Dive into ES6 features at <a href="https://www.freecodecamp.org/news/ecmascript-6-es6-cheat-sheet/" target="_blank" rel="noopener noreferrer">freeCodeCamp</a>.
          </p>
          <h2>8. Debugging Tips</h2>
          <p>
            Debugging is a crucial part of programming. Learn to debug JavaScript with the Chrome DevTools or Firefox Developer Tools to find and fix errors more efficiently. Check out debugging tips on <a href="https://developer.chrome.com/docs/devtools/javascript/" target="_blank" rel="noopener noreferrer">Chrome DevTools Docs</a>.
          </p>
          <h2>9. Best Practices</h2>
          <p>
            Following best practices like naming conventions, code simplicity, and documentation can significantly improve your code's readability and efficiency. Familiarize yourself with JavaScript best practices at <a href="https://www.w3schools.com/js/js_best_practices.asp" target="_blank" rel="noopener noreferrer">W3Schools</a>.
          </p>
          <h2>10. Resources for Continued Learning</h2>
          <p>
            Continuous learning is key to mastering JavaScript. Explore documentation, tutorials, and community forums to further your knowledge. A comprehensive resource is the <a href="https://eloquentjavascript.net/" target="_blank" rel="noopener noreferrer">Eloquent JavaScript book</a>, available online for free.
          </p>
        </section>
        <BlogNavBar />
      </article>
    );
  };
  
export default JavaScriptCheatSheetBlog;
