import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FaServicestack, FaEnvelopeOpenText, FaLaptop, FaEnvelope, FaWhatsapp, FaMobile, FaCartArrowDown, FaPencilAlt, FaCarSide, FaMicrochip, FaReact, FaSketch, FaShoppingCart } from 'react-icons/fa';
import logo from './assets/newlogo.png';
import './styles/Sidebar.css';
import signallogo from './assets/SignalIcon.png';
import { TfiWrite } from "react-icons/tfi";
import { GiDolphin } from "react-icons/gi";

const SidebarContainer = styled.div`
  height: 100vh;
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #004d99; /* Rich blue background */
  box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  z-index: 999;
  overflow-y: auto;
`;

const LogoContainer = styled.div`
  margin-top: 5vh;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: #eee;
`;

const NavItem = styled(Link)`
  text-decoration: none;
  color: #fff; /* White color for readability */
  margin: 15px 0;
  font-size: 15px; /* Slightly larger font size */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  z-index: 999;
  &:hover {
    color: #ff5722; /* Vibrant color for hover state */
    transform: translateX(10px); /* Slide right animation */
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
`;

const IconStyleWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Size of the icon */
  z-index: 9999;
`;

// Adding a subtle background image for a parallax-like effect
const BackgroundEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('path/to/your/background/image.jpg') no-repeat center center fixed;
  background-size: cover;
  opacity: 0.2; // Ensure the background is subtle
  z-index: -1;
`;

const DropdownMenu = styled.div`
  background: #003366; /* Slightly darker blue for contrast */
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 20px; /* Indent dropdown items */
  width: 100%;
  z-index: 999;
`;

const DropdownItem = styled(Link)`
  color: #fff;
  padding: 10px 0;
  font-size: 13px;
  text-decoration: none;

  &:hover {
    color: #ff5722; /* Matching hover color for consistency */
    transform: translateX(10px); /* Slide right animation for dropdown items */
  }
`;

const ContactOption = styled.a`
  color: #fff;
  padding: 10px 0;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    color: #ff5722; /* Matching hover color for consistency */
    transform: translateX(10px); /* Slide right animation */
  }
`;

const Sidebar = () => {
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isWebsitesDropdownOpen, setIsWebsitesDropdownOpen] = useState(false);
  const [isContactDropdownOpen, setIsContactDropdownOpen] = useState(false);
  const navigate = useNavigate(); // Instantiate the useNavigate hook

  // Function to navigate to /blogs
  const navigateToBlogs = () => {
    navigate('/nftblogs'); // Navigate to /blogs route
  };
  const navigateToFlipper = () => {
    navigate('/Flipper-Services'); // Navigate to /blogs route
  };

  // Toggle functions
  const toggleServicesDropdown = () => setIsServicesDropdownOpen(!isServicesDropdownOpen);
  const toggleWebsitesDropdown = () => setIsWebsitesDropdownOpen(!isWebsitesDropdownOpen);
  const toggleContactDropdown = () => setIsContactDropdownOpen(!isContactDropdownOpen);

  return (
    <SidebarContainer>
      <Helmet>
        <link rel="preload" as="image" href={logo} />
      </Helmet>
      <BackgroundEffect /> {/* Keep as is */}
      <LogoContainer>
        <React.Suspense fallback={<Placeholder />}>
         <Link to="/">
           <LogoImage src={logo} alt="neal-frazier-logo" />
           </Link>
        </React.Suspense>
      </LogoContainer>

      {/* Services Dropdown */}
      <NavItem as="div" onClick={toggleServicesDropdown}>
        <IconStyleWrapper><FaServicestack /></IconStyleWrapper>Services
      </NavItem>
      {isServicesDropdownOpen && (
        <DropdownMenu>
          <DropdownItem to="/services/web-development"><FaReact /> Web Development</DropdownItem>
          <DropdownItem as="a" href="https://openaigpttech.nealfrazier.tech" target="_blank" rel="noopener noreferrer"><FaMicrochip /> Open AI GPT Tech</DropdownItem>
          <DropdownItem as="a" href="https://valetninjas.nealfrazier.tech" target="_blank" rel="noopener noreferrer"><FaCarSide /> Valet Ninjas</DropdownItem>
        </DropdownMenu>
      )}
      <NavItem as="div" onClick={navigateToFlipper}>
        <IconStyleWrapper>
          <GiDolphin />
        </IconStyleWrapper>
        Flipper Zero
      </NavItem>
      {/* Websites Dropdown */}
      <NavItem as="div" onClick={toggleWebsitesDropdown}>
        <IconStyleWrapper><FaLaptop /></IconStyleWrapper>Website Dev
      </NavItem>
      {isWebsitesDropdownOpen && (
        <DropdownMenu>
          <DropdownItem to="/websites/portfolio"><FaSketch /> Theme Dev</DropdownItem>
          <DropdownItem to="/websites/e-commerce"><FaCartArrowDown /> E-Commerce Dev</DropdownItem>
          <DropdownItem to="/websites/blogs"><FaPencilAlt /> Blog Dev</DropdownItem>
        </DropdownMenu>
      )}

      <NavItem as="div" onClick={navigateToBlogs}>
        <IconStyleWrapper>
          <TfiWrite />
        </IconStyleWrapper>
        NFT Blogs
      </NavItem>
      <NavItem as="a" href="https://store.nealfrazier.tech" target="_blank" rel="noopener noreferrer">
        <IconStyleWrapper>
          <FaShoppingCart />
        </IconStyleWrapper>
        Store
      </NavItem>
      <NavItem as="div" onClick={toggleContactDropdown}><IconStyleWrapper><FaEnvelopeOpenText /></IconStyleWrapper>Contact</NavItem>
      {isContactDropdownOpen && (
        <DropdownMenu>
          <ContactOption href="tel:+19482047987"><FaMobile />Phone Call</ContactOption>
          <ContactOption href="mailto:business@nealfrazier.tech"><FaEnvelope />E-Mail</ContactOption>
          <ContactOption href="https://api.whatsapp.com/send?phone=7572689454" target="_blank"><FaWhatsapp />WhatsApp</ContactOption>
          <ContactOption href="https://signal.me/#p/+17572689454" target="_blank"><img src={signallogo} alt="signal-logo" height="20" />Signal</ContactOption>
          {/* Additional contact methods can be added here */}
        </DropdownMenu>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
