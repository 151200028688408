import React from 'react';
import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';

const ParticleBackground = () => {
  const customInit = (main) => {
    loadSlim(main);
  };

  const particlesOptions = {
    fullScreen: {
      enable: true,
      zIndex: -1, // Ensure it's behind all content
    },
    particles: {
      number: {
        value: 5, // Increase number for more particles
        density: {
          enable: true,
          area: 800,
        },
      },
      color: {
        value: ["#ff6191", "#33E3FF", "#fff941", "#b2ff9e"], // Array of colors for vibrant particles
      },
      shape: {
        type: "circle", // Can also try 'star' or 'polygon'
        stroke: {
          width: 0,
          color: "#000000",
        },
        polygon: {
          nb_sides: 5, // Use with 'polygon' type
        },
      },
      opacity: {
        value: 0.8,
        random: true,
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0.1,
          sync: false,
        },
      },
      size: {
        value: 5,
        random: true,
        anim: {
          enable: true,
          speed: 1,
          size_min: 0.1,
          sync: false,
        },
      },
      line_linked: {
        enable: false, // Disable for independent particles
      },
      move: {
        enable: true,
        speed: 5,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out", // Particles move out of canvas
        attract: {
          enable: false,
        },
      },
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "repulse", // Try "bubble" for fun hover effects
        },
        onclick: {
          enable: true,
          mode: "push", // Adds new particles on click
        },
      },
      modes: {
        bubble: {
          distance: 200,
          size: 20,
          duration: 2,
          opacity: 0.8,
          speed: 3,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
      },
    },
    retina_detect: true,
  };
  

  return <Particles id="tsparticles" init={customInit} options={particlesOptions} />;
};

export default ParticleBackground;
