import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { motion } from 'framer-motion';

const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
`;

const CustomDialogContent = styled(DialogContent)`
  text-align: center;
  padding: 20px;
`;

const CustomDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const StorePopup = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        component: motion.div,
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.5 }
      }}
    >
      <CustomDialogTitle>
        <ShoppingCartIcon style={{ marginRight: '10px' }} />
        Special Offer!
      </CustomDialogTitle>
      <CustomDialogContent>
        <Typography variant="h6" gutterBottom>
          Check out our amazing deals at <a href="https://store.nealfrazier.tech" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}>store.nealfrazier.tech</a>!
        </Typography>
        <Typography variant="body1" style={{ marginTop: '15px' }}>
          <CheckCircleOutlineIcon style={{ marginRight: '5px', verticalAlign: 'middle' }} />
          We have 19 packages available.
        </Typography>
        <Typography variant="body1" style={{ marginTop: '15px' }}>
          <CheckCircleOutlineIcon style={{ marginRight: '5px', verticalAlign: 'middle' }} />
          The hot deal is a one-page portfolio for only $199!
        </Typography>
        <Typography variant="body1" style={{ marginTop: '15px' }}>
          <CheckCircleOutlineIcon style={{ marginRight: '5px', verticalAlign: 'middle' }} />
          All sites come with lifetime deployment, full ownership of code, high detail design, fully responsive, and SEO optimized.
        </Typography>
      </CustomDialogContent>
      <CustomDialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

export default StorePopup;
