import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardActionArea, CardContent, CardMedia, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from '../assets/newlogo.png';
import './Portfolio.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const StyledContainer = styled(Container)({
  marginTop: '20px',
  backgroundColor: '#f5f5f5',
  padding: '20px',
});

const StyledCard = styled(Card)({
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.5s ease',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  },
});

const StyledButton = styled(Button)({
  marginTop: '20px',
});

const StyledImage = styled('img')({
  width: '100px',
  margin: '0 auto',
  display: 'block',
});

const profileStyles = [
  {
    id: 1,
    title: "Theme One",
    description: "A modern and clean design for admin dashboards, featuring customizable widgets, charts, and tables to efficiently manage data and track key performance indicators.",
    imageUrl: "/template1.jpg",
  },
  {
    id: 2,
    title: "Theme Two",
    description: "An enterprise-class UI design framework for building scalable and complex admin dashboards. Offers a wide range of components, layouts, and theming options for ultimate customization.",
    imageUrl: "/template2.jpeg",
  },
  {
    id: 3,
    title: "Theme Three",
    description: "A bright and vibrant portfolio theme with smooth animations, interactive elements, and multiple layout options to showcase your creative work in a visually stunning way.",
    imageUrl: "/template3.jpg",
  },
  {
    id: 4,
    title: "Theme Four",
    description: "A minimalist and sleek portfolio theme with a focus on clean typography, high-quality images, and ample white space to let your projects shine.",
    imageUrl: "/template4.jpg",
  },
  {
    id: 5,
    title: "Theme Five",
    description: "A creative and unique landing page theme with bold colors, dynamic layouts, and engaging calls to action to capture your audience's attention and drive conversions.",
    imageUrl: "/template5.jpg",
  },
  {
    id: 6,
    title: "Theme Six",
    description: "An elegant and professional landing page theme with a calming color palette, minimalist design, and clear messaging to showcase your products or services in a sophisticated way.",
    imageUrl: "/template6.jpg",
  },
  {
    id: 7,
    title: "Theme Seven",
    description: "A fully featured React components library for admin dashboards, offering a comprehensive set of pre-built components, customizable themes, and seamless integration with popular data visualization libraries.",
    imageUrl: "/template7.png",
  },
  {
    id: 8,
    title: "Theme Eight",
    description: "A powerful yet simple design framework for portfolio and business websites, providing flexible components, easy customization options, and a focus on performance and user experience.",
    imageUrl: "/template8.jpg",
  },
  {
    id: 9,
    title: "Theme Nine",
    description: "The App Framework for Startups, designed to help you quickly launch and scale your business website with robust features, enterprise-grade security, and seamless integrations.",
    imageUrl: "/template9.jpg",
  },
  {
    id: 10,
    title: "Theme Ten",
    description: "A Material Design theme for creating elegant portfolio or business websites, featuring clean lines, smooth animations, and a focus on user experience based on Google's Material Design principles.",
    imageUrl: "/template10.jpg",
  },
];

const Portfolio = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => {
    setIsContactModalOpen(true);
  };

  const closeModal = () => {
    setIsContactModalOpen(false);
  };

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>Neal Frazier Tech - Templates & Themes</title>
        <meta name="description" content="Discover how Neal Frazier Tech can help you create, purchase, and find the perfect templates and themes for your project. Contact us today to bring your vision to life." />
        <meta name="keywords" content="templates, themes, web design, Neal Frazier Tech, web development, customization, outsourcing" />
        <meta property="og:title" content="Neal Frazier Tech - Templates & Themes" />
        <meta property="og:description" content="Learn about the services Neal Frazier Tech offers for creating, purchasing, and finding templates and themes to suit your project needs. Get in touch with us today." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Neal Frazier Tech - Templates & Themes",
              "description": "Discover how Neal Frazier Tech can help you create, purchase, and find the perfect templates and themes for your project. Contact us today to bring your vision to life.",
              "publisher": {
                "@type": "Organization",
                "name": "Neal Frazier Tech",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://nealfrazier.tech/logo.png"
                }
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://nealfrazier.tech/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Templates & Themes",
                    "item": "https://nealfrazier.tech/portfolio"
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <StyledContainer>
        <Typography sx={{ marginTop: '80px'}} variant="h2" gutterBottom data-aos="slide-right">
          Templates & Themes by Neal Frazier Tech
        </Typography>
        <Typography variant="body1" gutterBottom data-aos="slide-left">
          At Neal Frazier Tech, we specialize in creating, sourcing, and customizing templates and themes to meet your specific needs. Whether you need a modern admin dashboard, a vibrant portfolio theme, or a sleek business website, we have the expertise to bring your vision to life. Contact us today to discover how we can help you find or create the perfect design for your project.
        </Typography>

        <Slider {...settings} data-aos="fade">
          {profileStyles.map((style) => (
            <div key={style.id}>
              <StyledCard data-aos="flip-up">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={`Profile style ${style.title}`}
                    height="140"
                    image={style.imageUrl}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {style.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {style.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </StyledCard>
            </div>
          ))}
        </Slider>

        <Typography variant="h4" gutterBottom data-aos="fade-up">
          Influence and Smart Choices of Templates and Themes
        </Typography>
        <Typography variant="body1" gutterBottom data-aos="fade-up">
          Using templates and themes can significantly reduce development time and ensure a consistent design language across your projects. It's a smart choice for both small businesses and large enterprises looking to maintain a professional and cohesive online presence. At Neal Frazier Tech, we understand the impact of good design and offer a range of templates that can be customized to meet your unique requirements.
        </Typography>
        
        <Typography variant="h4" gutterBottom data-aos="fade-up">
          Custom Solutions for Your Needs
        </Typography>
        <Typography variant="body1" gutterBottom data-aos="fade-up">
          Whether you need to buy, find, or duplicate an existing site, Neal Frazier Tech offers tailored solutions to fit your needs. Our team can create custom templates and themes that align with your brand identity and project goals, ensuring a unique and effective web presence.
        </Typography>

        <StyledButton variant="contained" color="primary" onClick={openContactModal}>
          Contact Us Today!
        </StyledButton>
        
        <Dialog open={isContactModalOpen} onClose={closeModal} maxWidth="sm" fullWidth>
          <DialogTitle>Contact Us Today!</DialogTitle>
          <DialogContent>
            <StyledImage src={logo} alt="logo" />
            <Typography gutterBottom>
              Interested in our templates and themes? Reach out to us to discuss your project needs and how we can help you achieve your goals with our custom designs and outsourced solutions.
            </Typography>
            <form name="contact" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />
              <TextField margin="dense" label="Your Name" type="text" fullWidth required name="name" />
              <TextField margin="dense" label="Your Email" type="email" fullWidth required name="email" />
              <TextField margin="dense" label="Message" type="text" fullWidth multiline rows={4} required name="message" />
              <DialogActions>
                <Button onClick={closeModal} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </StyledContainer>
    </>
  );
};

export default Portfolio;
