import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Navbar from './Navbar';
import Footer from './Footer';
import LoadingScreen from './LoadingScreen';
import AppRoutes from './AppRoutes'; // Import the new AppRoutes component
import './App.css';
import ScrollToTop from './ScrollToTop';
import StorePopup from './components/StorePopUp';

const ToggleButton = styled.button`
  position: fixed;
  z-index: 9999;
  top: 30px;
  left: 15px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

function App() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading, replace with actual logic to load initial resources
    setTimeout(() => {
      setIsLoading(false);
    }, 50); // Adjust the loading time as needed
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Router>
          <ScrollToTop />
          <StorePopup />
          <ToggleButton onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
            <FontAwesomeIcon className="fa-icons-sidebar" icon={isSidebarVisible ? faTimes : faBars} />
          </ToggleButton>
          {isSidebarVisible && <Sidebar />}
          <Navbar />
          <AppRoutes /> {/* Use the new AppRoutes component */}
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;
