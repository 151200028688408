import React from 'react';
import Typewriter from 'typewriter-effect';
import { Box } from '@mui/material';
import './styles/Hero.css'; // Ensure this CSS file includes the necessary styles

const HeroSection = () => {
  return (
    <Box
      component="section"
      className="hero-section"
      sx={{
        marginTop: '80px',
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '20vh',
        borderBlockEnd: '10px solid white',
        borderBlockStyle: 'dashed dotted',
        backgroundColor: 'transparent', // Blue from the logo
        color: '#FFFFFF', // White text
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
      <Box className="typewriter-container">
        <Typewriter
          options={{
            strings: [
              "Empower Your Presence Online and Onsite",
              "Welcome to Neal Frazier.tech",
              "Elevating businesses worldwide with top-tier web solutions and valet services.",
              "Innovative software development for modern businesses",
              "Seamless web development tailored to your needs",
              "Expert app development for all platforms",
              "Cutting-edge RFID and NFC card solutions",
              "Boost your business with our e-commerce expertise",
              "Personalized website design for individuals",
              "Custom website solutions for small businesses",
              "Your partner in digital transformation",
              "Secure and reliable networking services",
              "AI-driven solutions to enhance your operations",
              "Professional blogging services to elevate your brand",
              "Engage customers with our dynamic web solutions",
              "Maximize online visibility with effective SEO strategies",
              "Streamlined valet services for your convenience",
              "Tech solutions that drive growth and success",
              "Reliable tech support for all your needs",
              "Comprehensive IT consulting services",
              "Building a digital future, one solution at a time",
              "Your trusted partner in tech innovation",
              "Enhancing user experiences with intuitive design",
              "Transforming ideas into reality with expert coding",
              "Collaborate with us for unmatched tech services"
            ],
            autoStart: true,
            loop: true,
            deleteSpeed: 50,
            delay: 75,
          }}
        />
      </Box>
    </Box>
  );
};

export default HeroSection;
