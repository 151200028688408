// ContactForm.js
import React, { useState } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  color: #333;
  
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 100px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #ff5722;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #e56122;
  }
`;

const ContactForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  

  return (
    <FormContainer>
        <h2>Web Development Service Contact Form</h2>
        <form name="webdev-contact" method="POST" data-netlify="true">
      
  <input type="hidden" name="form-name" value="webdev-contact" />
  <input type="hidden" name="bot-field" />
        <FormInput
        aria-label="Your Name"
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <FormInput
        aria-label="Your Email"
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <FormTextarea
        aria-label="Description"
          name="description"
          placeholder="Describe your project..."
          value={formData.description}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Submit</SubmitButton>
      </form>
    </FormContainer>
  );
};

export default ContactForm;
