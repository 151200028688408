import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Container, Box, Typography, Card, CardContent, Avatar } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';
import './styles/LandingPage.css';

// Animation for the card hover effect
const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const TestimonialsContainer = styled(Container)`
  background: #f0f0f0;
  padding: 20px;
  text-align: left;
  margin-top: 50px;
  border-radius: 20px;
  
  animation: ${fadeIn} 1s ease-in-out;
`;

const TestimonialCard = styled(Card)`
  padding: 20px;
  background: white;
  margin: 20px auto;
  max-width: 80%;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition: 1s ease-in;
  &:hover {
    animation: ${float} 1s ease-in-out;
  }
`;

const Name = styled(Typography)`
  color: #333;
  margin-top: 15px;
`;

const Role = styled(Typography)`
  color: #777;
  margin-bottom: 15px;
  font-style: italic;
`;

const Text = styled(Typography)`
  color: #555;
  font-size: 16px;
  font-family: 'Orbitron';
`;

const QuoteMark = styled(FormatQuote)`
  font-size: 80px;
  color: #ff5722;
  position: absolute;
  top: -20px;
  left: -20px;
`;

const TestimonialCardContent = styled(CardContent)`
  position: relative;
  padding-left: 40px;
`;

const AvatarIcon = styled(Avatar)`
  width: 60px;
  height: 60px;
  margin-right: 20px;
`;

const testimonials = [
  {
    id: 1,
    name: "Taylor Reed",
    role: "Entrepreneur",
    testimonial: "Neal's innovative solutions have not only streamlined our operations but also significantly boosted our online presence. A true tech visionary.",
    avatar: "/assets/TaylorReed.jpg" // Placeholder, replace with actual image URL
  },
  {
    id: 2,
    name: "Jamie Lannister",
    role: "Marketing Director",
    testimonial: "The custom AI solution Neal developed for us has revolutionized the way we analyze data. His expertise is unmatched.",
    avatar: "/assets/JamieLannister.jpg" // Placeholder, replace with actual image URL
  },
  {
    id: 3,
    name: "Alex Quinn",
    role: "Startup Founder",
    testimonial: "Thanks to Neal, our website now stands out in a crowded digital landscape. His attention to detail and creativity is remarkable.",
    avatar: "/assets/AlexQuinn.webp" // Placeholder, replace with actual image URL
  },
  {
    id: 4,
    name: "Samara Leigh",
    role: "Product Manager",
    testimonial: "Neal's approach to problem-solving is both innovative and practical. His work on our project management tools has been a game changer.",
    avatar: "/assets/SamaraLeigh.webp" // Placeholder, replace with actual image URL
  },
  {
    id: 5,
    name: "Chris Field",
    role: "CEO, TwistTech Innovations Inc.",
    testimonial: "Working with Neal has been enlightening. His knowledge in web development and AI has propelled our projects to new heights.",
    avatar: "/assets/ChrisField.webp" // Placeholder, replace with actual image URL
  }
];

const Testimonials = () => {
  return (
    <TestimonialsContainer maxWidth="md">
      <Typography sx={{fontFamily: 'Orbitron'}}variant="h2" className="services-h2" align="center" gutterBottom>
        Testimonials
      </Typography>
      {testimonials.map(({ id, name, role, testimonial, avatar }) => (
        <TestimonialCard key={id}>
          <TestimonialCardContent>
            <QuoteMark />
            <Box display="flex" alignItems="center" mb={2}>
              <AvatarIcon src={avatar} alt={name} />
              <Box>
                <Name variant="h6">{name}</Name>
                <Role variant="body2">{role}</Role>
              </Box>
            </Box>
            <Text variant="body1">{testimonial}</Text>
          </TestimonialCardContent>
        </TestimonialCard>
      ))}
    </TestimonialsContainer>
  );
};

export default Testimonials;
