import React from 'react';
import styles from '../styles/BlogPost.module.css';
import logo from './NealFrazier.png';
import BlogNavBar from '../BlogNavBar';
import { Link } from 'react-router-dom';

const CreatingCustomReactHooksBlog = () => {
  return (
    <article className={styles.blogPost}>
      <header className={styles.blogHeader}>
        <Link to="nftblogs">
        <img src={logo} alt="Creating Custom React Hooks" />
        </Link>
        <h1>Creating Custom React Hooks</h1>
      </header>

      <section className={styles.blogContent}>
        <p>
          React Hooks revolutionized how we manage state and side effects in functional components. While React provides a set of built-in Hooks like `useState`, `useEffect`, and `useContext`, you can supercharge your development workflow by creating your own custom Hooks.
        </p>

        <h2>Why Custom React Hooks?</h2>
        <p>
          Custom Hooks allow you to extract reusable logic from your components, making your code more organized, maintainable, and easier to test. Here are some key benefits:
        </p>
        <ul>
          <li>**Reusability:** Share logic across multiple components without prop drilling or complex component hierarchies.</li>
          <li>**Abstraction:** Encapsulate complex logic into self-contained units, improving code readability.</li>
          <li>**Testability:** Isolate and test the logic of custom Hooks independently from your components.</li>
        </ul>

        <h2>Building Your First Custom Hook</h2>
        <p>
          Let's craft a simple custom Hook called `useLocalStorage` to illustrate the concept:
        </p>

        <pre className={styles.codeSnippet}>
          {`
import { useState, useEffect } from 'react';

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      const valueToStore = 
        storedValue instanceof Function ? storedValue(storedValue) : storedValue;
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}

export default useLocalStorage;
          `}
        </pre>

        <h2>Advanced Custom Hooks</h2>
        <p>
          As you become more comfortable with custom Hooks, you can explore more sophisticated patterns:
        </p>
        <ul>
          <li>**Data Fetching:** Create Hooks to abstract data fetching logic using libraries like <a href="https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API" target="_blank" rel="noopener noreferrer">Fetch API</a> or <a href="https://axios-http.com/" target="_blank" rel="noopener noreferrer">Axios</a>.</li>
          <li>**Form Handling:** Simplify form management with custom Hooks that handle validation, state, and submission.</li>
          <li>**UI Interactions:** Implement custom Hooks for animations, drag-and-drop, or other interactive behaviors.</li>
        </ul>

        <h2>Best Practices</h2>
        <p>
          To write effective custom Hooks:
        </p>
        <ul>
          <li>**Follow the Rules of Hooks:** Ensure your custom Hooks adhere to <a href="https://react.dev/reference/rules/rules-of-hooks" target="_blank" rel="noopener noreferrer">React's Rules of Hooks</a> to avoid unexpected behavior.</li>
          <li>**Name with "use":** Prefix your custom Hook names with "use" to signal their purpose and adhere to conventions.</li>
          <li>**Keep Them Pure:** Avoid side effects within custom Hooks and rely on `useEffect` for managing external interactions.</li>
        </ul>
      </section>
      <BlogNavBar />
    </article>
  );
};

export default CreatingCustomReactHooksBlog;
