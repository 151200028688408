import React from 'react';
import { Container, Box, Typography, Button, Card, CardContent, CardMedia } from '@mui/material';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Code, DirectionsCar, Memory } from '@mui/icons-material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import digitalserviceslogo from './assets/digital-services.png';
import parkinglogo from './assets/parking.png';
import friendslogo from './assets/friends.png';

const sliderSettings = {
  
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  cssEase: "ease-in-out",
  centerMode: true,
  centerPadding: '0',
};

const services = [
  {
    logo: digitalserviceslogo,
    icon: <Code color="primary" sx={{ fontSize: 40 }} />,
    title: 'Elite Web Development & Software Engineering',
    description: 'Embark on a transformative journey with custom, cutting-edge digital solutions that dominate the digital landscape.',
    link: '/services/web-development',
    buttonText: 'Discover Elite Web Solutions',
  },
  {
    logo: parkinglogo,
    icon: <DirectionsCar color="primary" sx={{ fontSize: 40 }} />,
    title: 'Revolutionary Valet & Parking Solutions',
    description: 'Leverage the latest in efficiency and technology with state-of-the-art valet services.',
    link: 'https://valetninjas.nealfrazier.tech',
    buttonText: 'Elevate Your Valet Experience',
    external: true,
  },
  {
    logo: friendslogo,
    icon: <Memory color="primary" sx={{ fontSize: 40 }} />,
    title: 'Custom AI & GPT Solutions',
    description: 'Enhance business efficiency with AI and custom GPTs that ensure you lead in efficiency and insight.',
    link: 'https://openaigpttech.nealfrazier.tech',
    buttonText: 'Transform with AI Engineering',
    external: true,
  }
];

const ServicesSection = () => {
  return (
    <Container id="services" sx={{ py: 8, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h2" gutterBottom className="services-h2" sx={{ fontFamily: 'Orbitron', fontWeight: 'bold', textAlign: 'center', color: '#3f51b5' }}>
        Our Services
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: { xs: '100%', md: '80%' } }}>
          <Slider {...sliderSettings}>
            {services.map((service, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <Card sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '550px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: 4,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
                  },
                }} data-aos="fade-up">
                  <CardMedia
                    component="img"
                    image={service.logo}
                    alt={`${service.title} Logo`}
                    sx={{ height: 200, objectFit: 'contain' }}
                    onError={(e) => e.target.style.display = 'none'}
                  />
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      {service.icon}
                      <Typography variant="h5" component="h2" gutterBottom sx={{ ml: 2, fontWeight: 'bold', color: '#333' }}>
                        {service.title}
                      </Typography>
                    </Box>
                    <Typography variant="body2" paragraph sx={{ color: '#555' }}>
                      {service.description}
                    </Typography>
                    {service.external ? (
                      <Button
                        variant="contained"
                        color="primary"
                        href={service.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mt: 2 }}
                      >
                        {service.buttonText}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={service.link}
                        sx={{ mt: 2 }}
                      >
                        {service.buttonText}
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Container>
  );
};

export default ServicesSection;
