import React from 'react';
import styled from 'styled-components';

const ContactFormWrapper = styled.div`
  background: #f0f8ff;
  color: #00274c;
  padding: 50px 20px;
  border: 2px solid #00274c;
  border-radius: 10px;
  width: 50vw;
  margin: 0 auto;


  @media (max-width: 768px) {
    width: 70vw;
  }
`;


const FormTitle = styled.h2`
  color: #ff6600;
  text-align: center;
  margin-bottom: 20px;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #00274c;
  border-radius: 5px;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #00274c;
  border-radius: 5px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #00274c;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  background-color: #ff6600;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  font-size: 16px;

  &:hover {
    background-color: #e55b00;
  }
`;

const FlipperContactForm = () => {
  return (
    <ContactFormWrapper>
      <form name="flipper-contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="flipper-contact" />
        <FormTitle>Contact Us</FormTitle>
        <FormField>
          <Label htmlFor="name">Name</Label>
          <Input type="text" id="name" name="name" required />
        </FormField>
        <FormField>
          <Label htmlFor="email">Email</Label>
          <Input type="email" id="email" name="email" required />
        </FormField>
        <FormField>
          <Label htmlFor="phone">Phone Number</Label>
          <Input type="tel" id="phone" name="phone" required />
        </FormField>
        <FormField>
          <Label htmlFor="service">Service</Label>
          <Select id="service" name="service" required>
            <option value="NFC Door Card Creation">NFC Door Card Creation</option>
            <option value="RFID Key Duplication">RFID Key Duplication</option>
            <option value="TV Remote Programming">TV Remote Programming</option>
            <option value="Car Key Duplication">Car Key Duplication</option>
            <option value="Social Network NFC Cards">Social Network NFC Cards</option>
            <option value="Security Auditing">Security Auditing</option>
            <option value="Wireless Signal Analysis">Wireless Signal Analysis</option>
            <option value="Smart Home Integration">Smart Home Integration</option>
            <option value="Penetration Testing">Penetration Testing</option>
          </Select>
        </FormField>
        <FormField>
          <Label htmlFor="description">Description</Label>
          <Textarea id="description" name="description" rows="5" required></Textarea>
        </FormField>
        <SubmitButton type="submit">Submit</SubmitButton>
      </form>
    </ContactFormWrapper>
  );
};

export default FlipperContactForm;
