// LoadingScreen.js
import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import styled from 'styled-components';
import logo from './assets/newlogo.png';
import './LoadingScreen.css';

const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
`;

function LoadingScreen() {
  return (
    <Box className="loading-screen">
      <Box className="loading-content">
        <Logo src={logo} alt="Neal Frazier Tech Logo" />
        <Typography variant="h4" component="h2" sx={{ color: '#ff5722' }}>
          Loading...
        </Typography>
        <CircularProgress sx={{ color: '#007bff', marginTop: 2 }} size={60} thickness={4.5} />
      </Box>
    </Box>
  );
}

export default LoadingScreen;
