import React from 'react';
import styles from '../styles/BlogPost.module.css'; // Consistent CSS structure for the blog
import logo from './NealFrazier.png'; // Maintaining brand identity with the Neal Frazier logo
import BlogNavBar from '../BlogNavBar';
import { Link } from 'react-router-dom';

const WebScrapingGuyBlog = () => {
  return (
    <article className={styles.blogPost}>
      <header className={styles.blogHeader}>
        <Link to="/nftblogs">
          <img src={logo} alt="Adrian Horning - The Web Scraping Guy" />
        </Link>
        <h1>Adrian Horning - The Web Scraping Guy: A Detailed Review</h1>
      </header>
      <section className={styles.blogContent}>
        <p>
          Adrian Horning, widely known as The Web Scraping Guy, has made significant contributions to the tech community through his insightful and educational content on web scraping. His website, <a href="https://thewebscrapingguy.com" target="_blank" rel="noopener noreferrer">thewebscrapingguy.com</a>, Instagram account, <a href="https://www.instagram.com/adrianhorning/" target="_blank" rel="noopener noreferrer">@adrianhorning</a>, and YouTube channel, <a href="https://www.youtube.com/@the-web-scraping-guy/videos" target="_blank" rel="noopener noreferrer">@the-web-scraping-guy</a>, are rich sources of tutorials and tips for developers and data enthusiasts alike.
        </p>
        <h2>Who is Adrian Horning?</h2>
        <p>
          Adrian Horning is a seasoned web developer and data analyst specializing in web scraping. With years of experience in the industry, Adrian has built a reputation for his ability to simplify complex web scraping concepts and make them accessible to a broad audience. His passion for data extraction and analysis is evident in the quality of his tutorials and the depth of his knowledge.
        </p>
        <h2>Content and Tutorials</h2>
        <p>
          Adrian’s website and social media accounts feature a wide range of tutorials on web scraping, including:
        </p>
        <ul>
          <li><strong>How to Scrape Zillow with Node.js</strong>: A comprehensive guide on scraping real estate data from Zillow using Node.js.</li>
          <li><strong>How to use Proxies in Node.js</strong>: Detailed instructions on integrating proxies with your web scrapers.</li>
          <li><strong>How to upload Images to Supabase</strong>: A tutorial on handling image uploads using Supabase and Node.js.</li>
          <li><strong>How to take a screenshot with Puppeteer</strong>: Techniques for capturing screenshots of web pages using Puppeteer.</li>
          <li><strong>Scraping TikTok Ads from the Creative Center</strong>: Insights into extracting advertising data from TikTok's Creative Center.</li>
        </ul>
        <h2>Products</h2>
        <p>
          Adrian has developed several products that leverage his web scraping expertise:
        </p>
        <ul>
        <a href="https://tokfinder.com/" target="_blank" rel="noopener noreferrer"><li>🕺 <strong>Tok Finder</strong> | TikTok Leads</li></a>
          <a href="https://baddiefinder.com/" target="_blank" rel="noopener noreferrer"><li>🔥 <strong>Baddie Finder</strong> | Auto Swipe with AI</li></a>
          <a href="https://app.localbusinessleads.ai/" target="_blank" rel="noopener noreferrer"><li>🌎 <strong>Local Business Leads</strong> | Google Maps/Angi Scraper</li></a>
          <a href="https://adrianhorning.gumroad.com/l/hhqeu" target="_blank" rel="noopener noreferrer"><li>🏡 450k realtors from realtor.com ($500)</li></a>
          <a href="https://adrianhorning.gumroad.com/l/rqmgy" target="_blank" rel="noopener noreferrer"><li>⚒️ 287k contractors from angi.com ($500)</li></a>
        </ul>
        <h2>Press</h2>
        <p>
          Adrian's work has been featured in major publications, including:
        </p>
        <ul>
          <li><strong><a href="https://www.nytimes.com/2023/06/23/technology/reddit-moderators-users-api-protest.html" target="_blank" rel="noopener noreferrer">New York Times</a></strong></li>
          <li><strong><a href="https://www.404media.co/baddiefinder-tinder-bot/" target="_blank" rel="noopener noreferrer">404 Media</a></strong></li>
        </ul>
        <h2>Testimonials</h2>
        <p>
          Adrian has received numerous testimonials from satisfied clients and followers:
        </p>
        <blockquote>
          <p>
            "Just a quick note of 'thanks' to my man @adrian_horning_, who helped with a web-scraping project over the past few weeks. Recommend reaching out if you need help pulling together data from obscure websites." - Moses Kagan
          </p>
        </blockquote>
        <blockquote>
          <p>
            "Update on this: @adrian_horning_ is absolutely fantastic. Really excited about the live data feed he’s helping us set up!" - Sean O'Dowd
          </p>
        </blockquote>
        <blockquote>
          <p>
            ".@adrian_horning_ helped me on a recent project and is absolutely wicked at web scraping data and honestly just a good dude. Big fan!" - Sully 🤠
          </p>
        </blockquote>
        <blockquote>
          <p>
            "Have to shout out @adrian_horning_. The man knows how to scrape. If you have scraping projects hit him up." - Mitchell Wright 🇺🇦
          </p>
        </blockquote>
        <blockquote>
          <p>
            "Thanks to @adrian_horning_, my startup is alive again. 🚀 Keeping fingers crossed that the embedded headers in GET request won’t expire. 🤞🏼 #buildinpublic #indiehackers" - Yifan Goh
          </p>
        </blockquote>
        <h2>Notable Videos and Blog Posts</h2>
        <p>
          Adrian’s YouTube channel features several notable videos that have garnered significant attention:
        </p>
        <ul>
          <li><a href="https://www.youtube.com/watch?v=xaQ3p555uPU" target="_blank" rel="noopener noreferrer">My Social Media Scraper API's Demo</a></li>
          <li><a href="https://www.youtube.com/watch?v=Fe3M288x7FA" target="_blank" rel="noopener noreferrer">How To Scrape Zillow with Node.js</a></li>
          <li><a href="https://www.youtube.com/watch?v=qeVBtJUjJ-o" target="_blank" rel="noopener noreferrer">How to use Proxies in Node.js</a></li>
          <li><a href="https://www.youtube.com/watch?v=pa-zbHzW6Co" target="_blank" rel="noopener noreferrer">How to upload Images to Supabase</a></li>
          <li><a href="https://www.youtube.com/watch?v=J6pvBmyKjsA" target="_blank" rel="noopener noreferrer">How to take a screenshot with puppeteer (node.js)</a></li>
          <li><a href="https://www.youtube.com/watch?v=QQ_-T3KmXGE" target="_blank" rel="noopener noreferrer">Baddie Finder VSL</a></li>
          <li><a href="https://www.youtube.com/watch?v=HdLbAo-e2f4" target="_blank" rel="noopener noreferrer">I scraped 90k+ tiktok creators + emails 👀</a></li>
          <li><a href="https://www.youtube.com/watch?v=hJZmJcplHxc" target="_blank" rel="noopener noreferrer">I wrote a free script that scrape Facebook groups</a></li>
        </ul>
      
        <h2>GitHub Profile</h2>
        <p>
          For those interested in seeing Adrian's code and contributions to the open-source community, check out his GitHub profile: <a href="https://github.com/adrianhorning08" target="_blank" rel="noopener noreferrer">github.com/adrianhorning08</a>.
        </p>
        <h2>Conclusion</h2>
        <p>
          Adrian Horning, known as The Web Scraping Guy, is a valuable resource for anyone interested in web scraping. His dedication to sharing knowledge and promoting ethical practices has earned him a loyal following. By following his tutorials and guidance, you can enhance your web scraping skills and undertake more complex projects with confidence.
        </p>
        <h2>Call to Action</h2>
        <p>
          For more web scraping tutorials and insights, visit Adrian Horning’s website at <a href="https://thewebscrapingguy.com" target="_blank" rel="noopener noreferrer">thewebscrapingguy.com</a>, follow him on Instagram at <a href="https://www.instagram.com/adrianhorning/" target="_blank" rel="noopener noreferrer">@adrianhorning</a>, and subscribe to his YouTube channel at <a href="https://www.youtube.com/@the-web-scraping-guy/videos" target="_blank" rel="noopener noreferrer">@the-web-scraping-guy</a>. Additionally, explore his GitHub projects at <a href="https://github.com/adrianhorning08" target="_blank" rel="noopener noreferrer">github.com/adrianhorning08</a>.
        </p>
      </section>
      <BlogNavBar />
    </article>
  );
};

export default WebScrapingGuyBlog;
