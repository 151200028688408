import React from 'react';
import './ECommerce.css'; // Ensure the CSS file includes the responsive design rules
import { Button, Form } from 'react-bootstrap';
import clothinglogo from '../assets/clothing-shop.gif';
import electronicslogo from '../assets/electronics.gif';
import artgallerylogo from '../assets/artgallery.gif';
import ecommercelogo from '../assets/ecommerce.gif';
import homedecorlogo from '../assets/homedecor.gif';
import healthandwellnesslogo from '../assets/healthwellness.gif';
import foodlogo from '../assets/food.gif';
import sportslogo from '../assets/sportslogo.gif';
import bookstorelogo from '../assets/bookstore.gif';
import antiquelogo from '../assets/antiquelogo.gif';
import subscriptionlgo from '../assets/subscribe.gif';
import petSuppliesLogo from '../assets/petlogo.gif';
import diyHomeImprovementLogo from '../assets/homediy.gif';
import { Helmet } from 'react-helmet';

const ECommerce = () => {
  const sections = [
    {
      title: "Fashion Retailers",
      description: "Launch a visually stunning online boutique that showcases your collections with style. Our fashion e-commerce sites are designed to turn heads and open wallets.",
      logo: clothinglogo,
      alt: "clothing logo"
    },
    {
      title: "Electronics Marketplaces",
      description: "Offer the latest gadgets with an intuitive, secure platform that tech enthusiasts will love. From product comparisons to seamless checkout processes, we make tech shopping a breeze.",
      logo: electronicslogo,
      alt: "electronics logo"
    },
    {
      title: "Art Galleries",
      description: "Sell artwork online through a beautifully curated digital gallery. Our platforms support high-resolution images and immersive experiences to attract art lovers worldwide.",
      logo: artgallerylogo,
      alt: "art gallery logo"
    },
    {
      title: "Home Decor Stores",
      description: "Create a cozy online space that reflects the warmth of your home decor products. With interactive design tools and customer reviews, we help you inspire home transformations.",
      logo: homedecorlogo,
      alt: "home decor logo"
    },
    {
      title: "Health and Wellness Shops",
      description: "Promote a healthy lifestyle with an e-commerce site dedicated to wellness products. From online consultations to subscription services, we make wellness accessible.",
      logo: healthandwellnesslogo,
      alt: "health and wellness logo"
    },
    {
      title: "Gourmet Food Outlets",
      description: "Entice foodies with an online gourmet store that offers a taste of luxury. Our solutions include subscription boxes, recipe integrations, and wine pairing suggestions.",
      logo: foodlogo,
      alt: "food logo"
    },
    {
      title: "Sports Equipment Stores",
      description: "Cater to the active lifestyle with a dynamic online shop for sports and fitness gear. Features like product videos and fitness tips can enhance the shopping experience.",
      logo: sportslogo,
      alt: "sports logo"
    },
    {
      title: "Bookstores",
      description: "Ignite the imagination with an online bookstore that offers more than just books. Interactive reading lists, author events, and community forums can engage and grow your reader base.",
      logo: bookstorelogo,
      alt: "book store logo"
    },
    {
      title: "Antique and Collectibles Marketplaces",
      description: "Attract collectors with a specialized platform for rare finds. Our sites offer advanced search capabilities, authenticity verification, and a community for enthusiasts.",
      logo: antiquelogo,
      alt: "antique logo"
    },
    {
      title: "Subscription Services",
      description: "From beauty boxes to monthly snack deliveries, create a subscription service that keeps customers coming back. Our platforms support recurring payments, customer preferences, and gift subscriptions.",
      logo: subscriptionlgo,
      alt: "subscription logo"
    },
    {
      title: "Pet Supplies Store",
      description: "Delight your furry friends with our comprehensive range of pet supplies. From nutritious food options to entertaining toys and cozy beds, our pet store is a one-stop shop for all your pet care needs. Explore products that promote the health, happiness, and well-being of your beloved pets, all available with just a few clicks.",
      logo: petSuppliesLogo, // Ensure you have an appropriate logo imported as petSuppliesLogo
      alt: "Pet Supplies Store Logo"
    },
    {
      title: "DIY and Home Improvement",
      description: "Unleash your creativity and enhance your home with our DIY and home improvement store. Discover a vast selection of tools, materials, and accessories designed to empower your home projects. Whether you're remodeling your kitchen, creating a backyard oasis, or adding a personal touch to your living space, find everything you need to bring your vision to life.",
      logo: diyHomeImprovementLogo, // Ensure you have an appropriate logo imported as diyHomeImprovementLogo
      alt: "DIY and Home Improvement Logo"
    },
    
  ];

  return (
    <div className="ecommerce-container">
      <Helmet>
  <title>Transform Your Business with Our E-Commerce Solutions - Neal Frazier Tech</title>
  <meta name="description" content="Step into the future of commerce with Neal Frazier Tech. Our bespoke e-commerce platforms transform your online presence into a thriving ecosystem. Explore solutions for fashion retailers, electronics marketplaces, art galleries, home decor stores, health and wellness shops, gourmet food outlets, sports equipment stores, bookstores, antique marketplaces, subscription services, pet supplies stores, and DIY and home improvement." />
  <meta name="keywords" content="e-commerce solutions, online store, Neal Frazier Tech, fashion e-commerce, electronics marketplace, art gallery, home decor, health and wellness, gourmet food, sports equipment, bookstore, antique marketplace, subscription services, pet supplies, DIY and home improvement" />
  <meta property="og:title" content="Transform Your Business with Our E-Commerce Solutions - Neal Frazier Tech" />
  <meta property="og:description" content="Transform your online presence into a thriving ecosystem with Neal Frazier Tech's bespoke e-commerce platforms. Tailored solutions for fashion, electronics, art, home decor, wellness, gourmet food, sports, books, antiques, subscriptions, pets, and DIY." />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Transform Your Business with Our E-Commerce Solutions",
        "description": "Step into the future of commerce with Neal Frazier Tech. Our bespoke e-commerce platforms transform your online presence into a thriving ecosystem. Explore solutions for fashion retailers, electronics marketplaces, art galleries, home decor stores, health and wellness shops, gourmet food outlets, sports equipment stores, bookstores, antique marketplaces, subscription services, pet supplies stores, and DIY and home improvement.",
        "publisher": {
          "@type": "Organization",
          "name": "Neal Frazier Tech",
          "logo": {
            "@type": "ImageObject",
            "url": "https://nealfrazier.tech/logo.png"
          }
        },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://nealfrazier.tech/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "E-Commerce Solutions",
              "item": "https://nealfrazier.tech/e-commerce"
            }
          ]
        }
      }
    `}
  </script>
</Helmet>


      <div className="ecommerce-intro">
        <div className="ecommerce-intro-text">
      <h1 className="tracking-in-expand">Transform Your Business with Our E-Commerce Solutions</h1>
      <p>Step into the future of commerce with a partner that transforms your online presence into a thriving ecosystem. Our bespoke e-commerce platforms are more than just stores; they are the cornerstone of digital business success. Here's how we can revolutionize your business:</p>
      
      </div>
      <img src={ecommercelogo} alt="ecommerce-logo" className="ecommerce-logo" />
      
      </div>
      <div className="sections-container">
        {sections.map((section, index) => (
          <div key={index} className="section">
            <div className="text-content">
              <strong>{section.title}:</strong>
              <p>{section.description}</p>
            </div>
            <img src={section.logo} alt={section.alt} className="section-logo" />
          </div>
        ))}
      </div>
      <h2 className="center-h2">Start your journey today!</h2>
      <p className="center-p">Embark on a journey to redefine your business and create life-changing experiences for your customers. Each e-commerce solution is tailored to your unique needs, ensuring that your online store is not just a marketplace, but a destination.</p>
      
      <Form method="POST" className="contact-form" data-netlify="true" >
  <input type="hidden" name="form-name" value="e-commerce-contact" />
  <input type="hidden" name="bot-field" />
  <Form.Group controlId="formBasicName" className="custom-form-group">
    <Form.Label>Your Name:</Form.Label>
    <Form.Control type="text" name="name" placeholder="Enter your name" required />
  </Form.Group>
  <Form.Group controlId="formBasicEmail" className="custom-form-group">
    <Form.Label>Your Email:</Form.Label>
    <Form.Control type="email" name="email" placeholder="Enter your email" required />
  </Form.Group>
  <Form.Group controlId="formBasicMessage" className="custom-form-group">
    <Form.Label>Message (Describe Idea Regarding E-Commerce Services):</Form.Label>
    <Form.Control as="textarea" name="message" rows={3} placeholder="Your message" required />
  </Form.Group>
  <Button variant="primary" type="submit" className="contact-button">
    Begin Your E-Commerce Transformation Today
  </Button>
</Form>
     
    </div>
  );
};

export default ECommerce;
