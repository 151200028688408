import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/BlogNavBar.css';
import blogData from './data/blogs.json';

function BlogNavBar() {
  const location = useLocation();
  const [currentBlogId, setCurrentBlogId] = useState(null);
  const [visibleRange, setVisibleRange] = useState([0, 5]);

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const extractedId = parseInt(pathSegments[pathSegments.length - 1], 10);
    const matchingBlog = blogData.find(blog => blog.id === extractedId);

    if (matchingBlog) {
      setCurrentBlogId(matchingBlog.id);
      updateVisibleRange(matchingBlog.id);
    } else {
      setCurrentBlogId(null);
      setVisibleRange([0, 5]); // Default for non-blog routes
    }
  }, [location]);

  const updateVisibleRange = (id) => {
    const totalBlogs = blogData.length;
    const rangeSize = 5;
    let start = Math.max(0, id - 1);
    let end = start + rangeSize;

    if (end > totalBlogs) {
      end = totalBlogs;
      start = Math.max(0, end - rangeSize);
    }

    setVisibleRange([start, end]);
  };

  const handlePrevClick = () => {
    setCurrentBlogId(prev => {
      const newId = Math.max(1, prev - 1);
      updateVisibleRange(newId);
      return newId;
    });
  };

  const handleNextClick = () => {
    setCurrentBlogId(prev => {
      const newId = Math.min(blogData.length, prev + 1);
      updateVisibleRange(newId);
      return newId;
    });
  };

  const handleSliderChange = (event) => {
    const newId = parseInt(event.target.value, 10);
    setCurrentBlogId(newId);
    updateVisibleRange(newId);
  };

  return (
    <div>
      <nav className="blogNavBar">
        <button onClick={handlePrevClick} disabled={currentBlogId <= 1}>&lt;</button>
        {blogData.slice(...visibleRange).map(blog => (
          <Link key={blog.id} to={blog.url} className={blog.id === currentBlogId ? 'active' : ''}>
            {blog.id}
          </Link>
        ))}
        <button onClick={handleNextClick} disabled={currentBlogId >= blogData.length}>&gt;</button>
      </nav>
      <div className="slider-container">
        <input
          type="range"
          min="1"
          max={blogData.length}
          value={currentBlogId || 1}
          className="slider"
          onChange={handleSliderChange}
        />
      </div>
    </div>
  );
}

export default BlogNavBar;
