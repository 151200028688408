import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Container, Box } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroSection from './HeroSection';
import ContactMe from './ContactMe';
import Testimonials from './Testimonials';
import ParticleBackground from './ParticleBackground';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Spline from '@splinetool/react-spline';
import AboutSection from './AboutSection';
import ServicesSection from './ServicesSection';
import './styles/LandingPage.css';
import LazyLoadYouTubeVideo from './LazyLoadYoutubeVideo';

const LandingPage = () => {
  const [showSpline, setShowSpline] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const handleButtonClick = () => {
    setShowSpline(!showSpline);
  };
  const videoId = 'LLydyJ5cObg';
  const videoId2 = 'JQAX70GE5yQ';

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Virginia Beach Website Development Solutions - Neal Frazier Tech</title>
        <meta name="description" content="Welcome to Neal Frazier Tech, your one-stop solution for software development, web development, app development, and more." />
        <meta name="keywords" content="Neal Frazier Tech, software development, web development, app development, RFID, NFC cards, blogging, e-commerce, personal website design, small business website design" />
        <meta property="og:title" content="Home - Neal Frazier Tech" />
        <meta property="og:description" content="Discover the range of services offered by Neal Frazier Tech, from software and web development to RFID and NFC card production." />
      </Helmet>
      <HeroSection />
      <Box className="page-container2">
        <AboutSection />
        <LazyLoadYouTubeVideo videoId={videoId2} />
        <ServicesSection />
        <LazyLoadYouTubeVideo videoId={videoId} />
        <Testimonials />
        <Box component="section" id="contact" className="contact-section" sx={{ textAlign: 'center', my: 4 }}>
          <h1>Collaborate with the Best</h1>
          <p>Ready to redefine your business with unparalleled digital, AI, and valet solutions? Connect with me today.</p>
          <ContactMe />
        </Box>
        <ParticleBackground />
      </Box>
      <Box className="spline-button-container" sx={{ display: 'flex', justifyContent: 'center', my: 6 }}>
        <Button 
          variant="contained" 
          onClick={handleButtonClick} 
          sx={{
            backgroundColor: '#FF6F00', /* Orange from the logo */
            color: '#FFFFFF', /* White text */
            '&:hover': {
              backgroundColor: '#FF8F00' /* Lighter orange */
            },
            fontFamily: 'Orbitron, sans-serif',
            fontSize: '1.2rem',
            padding: '10px 20px'
          }}
        >
          {showSpline ? "Hide Our 3D Mascot" : "Show Our 3D Mascot"}
        </Button>
      </Box>
      {showSpline && <Spline scene="https://prod.spline.design/xd2R0RdN60e1I4lC/scene.splinecode" />}
    </Container>
  );
};

export default LandingPage;
