import React from 'react';
import { Container, Box, Typography, Button, Grid, Avatar, Card, CardContent } from '@mui/material';
import { LinkedIn, Code, Build, BusinessCenter, DirectionsCar, Security, Storage, ShoppingCart, PhoneAndroid, Lightbulb } from '@mui/icons-material';
import codinglogo from './assets/Neal-Frazier-Tech.jpeg';


const AboutSection = () => {
  return (
    <Container id="about" sx={{ py: 8 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" data-aos="scale-in-bottom">
        <Avatar
          src={codinglogo}
          alt="Virginia Beach Coding and Development Neal Frazier Tech"
          title="Virginia Beach Web Development and Software Engineering Neal Frazier Tech"
          sx={{ width: { xs: 200, md: 300 }, height: { xs: 200, md: 300 }, mb: { xs: 4, md: 0 }, mr: { md: 4 } }}
        />
        <Box>
          <Typography variant="h3" component="h1" gutterBottom className="tracking-in-expand" sx={{ color: 'white', fontFamily: 'Orbitron' , fontWeight: 'bolder' }}>
            Virginia Beach Website Development Solutions
          </Typography>
          <Typography variant="body1" paragraph>
            Website Development, Website Hosting, OpenAI LLM Solutions, Website Design, and much more. Specializing in digital solutions and providing unparalleled project management. Connect today to elevate your business with cutting-edge technology.
          </Typography>
         
          <Button
            variant="contained"
            color="primary"
            href="https://www.linkedin.com/in/nealfrazier/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mt: 2 }}
            startIcon={<LinkedIn />}
          >
            Connect Today
          </Button>
        </Box>
      </Box>
      
      <Grid container spacing={4} sx={{ mt: 6 }}>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up">
            <CardContent>
              <Code color="primary" sx={{ fontSize: 40 }} />
              <Typography  sx={{ fontFamily: 'Orbitron'}} variant="h5" component="h2" gutterBottom>
                Web Development
              </Typography>
              <Typography variant="body2">
                Custom, cutting-edge web development solutions that dominate the digital landscape.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up" data-aos-delay="200">
            <CardContent>
              <Build color="primary" sx={{ fontSize: 40 }} />
              <Typography sx={{ fontFamily: 'Orbitron'}} variant="h5" component="h2" gutterBottom>
                Software Engineering
              </Typography>
              <Typography variant="body2">
                Expertise in software engineering to transform your business with innovative technology.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up" data-aos-delay="400">
            <CardContent>
              <BusinessCenter color="primary" sx={{ fontSize: 40 }} />
              <Typography sx={{ fontFamily: 'Orbitron'}} variant="h5" component="h2" gutterBottom>
                Project Management
              </Typography>
              <Typography variant="body2">
                Unparalleled project management to ensure your projects are completed efficiently and effectively.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up" data-aos-delay="600">
            <CardContent>
              <DirectionsCar color="primary" sx={{ fontSize: 40 }} />
              <Typography sx={{ fontFamily: 'Orbitron'}} variant="h5" component="h2" gutterBottom>
                Valet Services
              </Typography>
              <Typography variant="body2">
                State-of-the-art valet services that leverage the latest in efficiency and technology.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up" data-aos-delay="800">
            <CardContent>
              <Security color="primary" sx={{ fontSize: 40 }} />
              <Typography sx={{ fontFamily: 'Orbitron'}} variant="h5" component="h2" gutterBottom>
                Cyber Security
              </Typography>
              <Typography variant="body2">
                Protecting your business with advanced cyber security solutions and practices.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up" data-aos-delay="1000">
            <CardContent>
              <Storage color="primary" sx={{ fontSize: 40 }} />
              <Typography sx={{ fontFamily: 'Orbitron'}}variant="h5" component="h2" gutterBottom>
                Data Management
              </Typography>
              <Typography variant="body2">
                Reliable data management services to ensure your data is secure and accessible.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up" data-aos-delay="1200">
            <CardContent>
              <ShoppingCart color="primary" sx={{ fontSize: 40 }} />
              <Typography sx={{ fontFamily: 'Orbitron'}}variant="h5" component="h2" gutterBottom>
                E-Commerce Solutions
              </Typography>
              <Typography variant="body2">
                Comprehensive e-commerce solutions to help you sell online effectively.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up" data-aos-delay="1400">
            <CardContent>
              <PhoneAndroid color="primary" sx={{ fontSize: 40 }} />
              <Typography sx={{ fontFamily: 'Orbitron'}}variant="h5" component="h2" gutterBottom>
                Mobile App Development
              </Typography>
              <Typography variant="body2">
                Creating mobile applications that provide an excellent user experience.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card data-aos="fade-up" data-aos-delay="1600">
            <CardContent>
              <Lightbulb color="primary" sx={{ fontSize: 40 }} />
              <Typography sx={{ fontFamily: 'Orbitron'}} variant="h5" component="h2" gutterBottom>
                AI and Machine Learning
              </Typography>
              <Typography variant="body2">
                Harnessing the power of AI and machine learning to drive business innovation.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutSection;
