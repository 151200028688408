// BackgroundAnimation.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import cloudImage from './cloud.png'; // Adjust path as needed
import sunImage from './sun.png'; // Adjust path as needed


const moveCloud = keyframes`
  from { transform: translateX(-100%); }
  to { transform: translateX(100%); }
`;

const moveSun = keyframes`
  0% { transform: translateX(100%); }
  50% { top: 50%; }
  100% { transform: translateX(-100%); }
`;

const Cloud = styled.div`
  position: absolute;
  top: 20%;
  left: -20%;
  width: 100px;
  height: auto;
  background-image: url(${cloudImage});
  background-size: contain;
  background-repeat: no-repeat;
  animation: ${moveCloud} 30s linear infinite;
`;

const Sun = styled.div`
  position: absolute;
  top: 10%;
  right: -20%;
  width: 150px;
  height: auto;
  background-image: url(${sunImage});
  background-size: contain;
  background-repeat: no-repeat;
  animation: ${moveSun} 60s linear infinite;
`;

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
`;

const CloudSmall = styled(Cloud)`
  top: 10%;
  left: 10%;
  width: 80px; // Smaller cloud
  animation-duration: 40s; // Slower movement
`;



const Star = styled.div`
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #fff;
  border-radius: 50%;
  animation: ${float} 3s infinite;
`;

// Generate random stars
const generateStars = (count) => {
  return Array.from({ length: count }, (_, index) => (
    <Star
      key={index}
      style={{
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
        animationDelay: `${Math.random() * 3}s`,
        animationDuration: `${2 + Math.random() * 3}s`,
      }}
    />
  ));
};

const BackgroundAnimation = () => (
  <>
    <Cloud />
    <CloudSmall />
    <Sun />
    {generateStars(100)}
  
  </>
);

export default BackgroundAnimation;
