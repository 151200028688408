import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';

const LazyLoadYouTubeVideo = ({ videoId }) => {
  const [isInView, setIsInView] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const currentRef = videoRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.25 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const videoSrc = isInView ? `https://www.youtube.com/embed/${videoId}` : '';

  return (
    <Box
      ref={videoRef}
      sx={{
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
        maxWidth: '100%',
        background: '#000',
        marginTop: '100px',
        marginBottom: '100px',
      }}
    >
      {isInView && (
        <iframe
          title="YouTube video player"
          src={videoSrc}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </Box>
  );
};

export default LazyLoadYouTubeVideo;
